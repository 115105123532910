.query-builder.bt-checkbox-glyphicons {
  .checkbox input[type='checkbox']:checked + label::after {
    font-family: 'Glyphicons Halflings';
    content: '\e013';
  }

  .checkbox label::after {
    padding-left: 4px;
    padding-top: 2px;
    font-size: 9px;
  }
}
